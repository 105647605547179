import { Outlet } from "react-router";
import { Header } from "./parts/Header";
import { Footer } from "./parts/Footer";
import "./styles/App.scss";
import ReactGA from "react-ga4";
import { useEffect } from "react";

function App() {
  const TRACKING_ID = "G-NHHDZ17P0M";

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div>
      <header>
        <Header />
        <Outlet />
        <Footer />
      </header>
    </div>
  );
}

export default App;
