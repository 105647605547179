import { Link, Stack } from "@mui/material";
import instagram from "../assets/instagram.svg";
export const Footer = () => {
  const isAppleDevice =
    navigator.platform.indexOf("Mac") > -1 ||
    navigator.platform.indexOf("iPhone") > -1 ||
    navigator.platform.indexOf("iPad") > -1 ||
    navigator.platform.indexOf("iPod") > -1;
  return (
    <Stack className="footer">
      <div className="footer-container">
        <div className="footer-container-box">
          <h1 className="footer-container-box-title">Produkte</h1>

          <a
            href={
              isAppleDevice
                ? "https://apps.apple.com/us/app/poro/id1673757616"
                : "https://play.google.com/store/apps/details?id=com.poro.deliveryApp"
            }
            className="footer-container-box-link"
          >
            Poro app
          </a>
          <a href="https://web.poro.al/" className="footer-container-box-link">
            Restorante dhe markete
          </a>
          <a
            href={
              isAppleDevice
                ? "https://apps.apple.com/al/app/poro-motorist/id6446115616"
                : "https://play.google.com/store/apps/details?id=com.poro.deliveryApp"
            }
            className="footer-container-box-link"
          >
            Poro Motorristi
          </a>
        </div>
        <div className="footer-container-box">
          <h1 className="footer-container-box-title">Poro</h1>

          <a
            href="https://instagram.com/poro.al?igshid=MzRlODBiNWFlZA=="
            className="footer-container-box-link"
          >
            Social
          </a>
          {/* <a href="" className="footer-container-box-link">
            Team
          </a>
          <a href="" className="footer-container-box-link">
            Startup
          </a> */}
        </div>
        <div className="footer-container-box">
          <h1 className="footer-container-box-title"> Resources</h1>

          {/* <a href="" className="footer-container-box-link">
            Poro Updates
          </a>*/}
          <a href="/terms" className="footer-container-box-link">
            Termat e perdorimit
          </a>
          <a href="/privacy" className="footer-container-box-link">
            Privatesia
          </a>
          <a href="mailto: Info@poro.al" className="footer-container-box-link">
            Contact
          </a>
        </div>
      </div>

      <div className="footer-authorRights">
        <p className="footer-authorRights-text"> &copy; 2023 Motomtech.</p>

        <Link
          href="https://instagram.com/poro.al?igshid=MzRlODBiNWFlZA=="
          className="footer-authorRights-link"
        >
          <img src={instagram} alt="" />
        </Link>
      </div>
    </Stack>
  );
};
