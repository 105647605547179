import { Box, Typography } from "@mui/material";

import RoundedButton from "../parts/RoundedButton";

export const HomeComponent = () => {
  const isAppleDevice =
    navigator.platform.indexOf("Mac") > -1 ||
    navigator.platform.indexOf("iPhone") > -1 ||
    navigator.platform.indexOf("iPad") > -1 ||
    navigator.platform.indexOf("iPod") > -1;
  return (
    <Box
      minHeight={"900px"}
      height={"100%"}
      className={"home"}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        "@media (max-width: 768px)": {
          minHeight: "100vh",
        },
      }}
    >
      <Box display={"flex"} flexDirection={"column"} px={2}>
        <Typography
          fontSize={48}
          fontWeight={700}
          color={"#fff"}
          margin={0}
          sx={{
            "@media (max-width: 768px)": {
              fontSize: 28.57,
              lineHeight: "42.86px",
            },
          }}
          className="title"
        >
          Poro ndryshon Gjithçka
        </Typography>
        <Typography
          fontSize={24}
          fontWeight={500}
          fontFamily={"Poppins"}
          color={"#fff"}
          marginY={"32px"}
          sx={{
            "@media (max-width: 768px)": {
              fontSize: 16.67,
              lineHeight: "20.32px",
              marginTop: "14px",
            },
          }}
          className="text"
        >
          I vetmi aplikacion falas për
          <br /> klientët dhe bizneset
        </Typography>
        <Box display={"flex"} gap={"32px"}>
          <RoundedButton
            link={
              isAppleDevice
                ? "https://apps.apple.com/us/app/poro/id1673757616"
                : "https://play.google.com/store/apps/details?id=com.poro.deliveryApp"
            }
            text={"Poro App"}
          />
          <RoundedButton
            style={{
              backgroundColor: "#fff",
              color: "black",
            }}
            text={" Regjistro Biznesin"}
            link={"https://web.poro.al/"}
          />
        </Box>
      </Box>
    </Box>
  );
};
