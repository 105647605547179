import React from "react";
import Cards from "../parts/Cards";

import { Autoplay } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// SwiperCore.use([Autoplay]);
const HomeServices = () => {
  const data = [
    {
      app: "restorant",
      title: "Restorantet dhe marketet",
      text: "Poro është platforma e vetme pa pagesë për Restorantet dhe Marketet që disponojnë korrierët e tyre.",
      btn: "Regjistrohu",
    },
    {
      app: "poroApp",
      title: "Poro App",
      text: "Me Poro, aplikacionin që operon në çdo qytet, shijoni gatimet dhe produktet më të mira lokale.",
      btn: {
        deviceApple: "apple",
        deviceAndroid: "android",
      },
    },
    {
      app: "deliveryApp",
      title: "Poro Motorristi",
      text: "Poro Motorristi është aplikacioni ynë për korrierët e bizneseve, që ju drejton me siguri në çdo destinacion.",
      btn: {
        deviceApple: "apple",
        deviceAndroid: "android",
      },
    },
  ];
  const swiperData = [
    {
      app: "restorant",
      title: "Restorantet dhe marketet",
      text: "Poro është platforma e vetme pa pagesë për Restorantet dhe Marketet që disponojnë korrierët e tyre.",
      btn: "Regjistrohu",
    },
    {
      app: "poroApp",
      title: "Poro App",
      text: "Me Poro, aplikacionin që operon në çdo qytet, shijoni gatimet dhe produktet më të mira lokale.",
      btn: {
        deviceApple: "apple",
        deviceAndroid: "android",
      },
    },
    {
      app: "deliveryApp",
      title: "Poro Motorristi",
      text: "Poro Motorristi është aplikacioni ynë për korrierët e bizneseve, që ju drejton me siguri në çdo destinacion.",
      btn: {
        deviceApple: "apple",
        deviceAndroid: "android",
      },
    },
    {
      app: "restorant",
      title: "Restorantet dhe marketet",
      text: "Poro është platforma e vetme pa pagesë për Restorantet dhe Marketet që disponojnë korrierët e tyre.",
      btn: "Regjistrohu",
    },
    {
      app: "poroApp",
      title: "Poro App",
      text: "Me Poro, aplikacionin që operon në çdo qytet, shijoni gatimet dhe produktet më të mira lokale.",
      btn: {
        deviceApple: "apple",
        deviceAndroid: "android",
      },
    },
    {
      app: "deliveryApp",
      title: "Poro Motorristi",
      text: "Poro Motorristi është aplikacioni ynë për korrierët e bizneseve, që ju drejton me siguri në çdo destinacion.",
      btn: {
        deviceApple: "apple",
        deviceAndroid: "android",
      },
    },
  ];
  return (
    <div className="service">
      <div className="service-container">
        <h1 className="service-container-title">Shërbimet</h1>
        <div className="service-container-cards">
          {data.map((item, i) => (
            <Cards key={i} item={item} />
          ))}
        </div>
        <div className="swiper">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              700: {
                slidesPerView: 2,
              },

              1023: {
                slidesPerView: 3,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {swiperData.map((item, i) => (
              <SwiperSlide key={i}>
                <Cards item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
