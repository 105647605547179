import React from "react";
import googlePlay from "../assets/googleplay.svg";
import appStore from "../assets/appstore.svg";
import { Stack, Link } from "@mui/material";

const SquareButton = ({ device, app }) => {
  function getAppUrl(device, app) {
    if (device === "apple" && app === "poroApp") {
      return "https://apps.apple.com/us/app/poro/id1673757616";
    } else if (device === "android" && app === "poroApp") {
      return "https://play.google.com/store/apps/details?id=com.poro.deliveryApp";
    } else if (device === "apple" && app === "deliveryApp") {
      return "https://apps.apple.com/al/app/poro-motorist/id6446115616";
    } else {
      return "https://play.google.com/store/apps/details?id=com.poro.motorApp";
    }
  }

  const link = getAppUrl(device, app);

  return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
      <Link href={link} target={"_blank"}>
        <img
          className="img"
          style={{
            maxWidth: "150px",
            width: "100%",
          }}
          alt=""
          src={device === "apple" ? appStore : googlePlay}
        />
      </Link>
    </Stack>
  );
};

export default SquareButton;
