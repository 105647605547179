import React, { useEffect, useState } from "react";
import SqaureButton from "./SquareButton";
import { Box } from "@mui/material";

const HorizontalCard = (props) => {
  const { title, description, image, reverse, btn, id, app } = props;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      className={`horizontalCard`}
      sx={screenWidth > 1023 && reverse ? { flexDirection: "row-reverse" } : {}}
      id={id}
    >
      <h2 className="horizontalCard-mobileTitle">{title}</h2>
      <img alt="" src={image} className="horizontalCard-image" />

      <div className=" horizontalCard-content">
        <h2 className="horizontalCard-content-title">{title}</h2>
        <p className="horizontalCard-content-description">{description}</p>
        {btn && (
          <div className="horizontalCard-content-btns">
            <SqaureButton device={"apple"} app={app} />
            <SqaureButton device={"android"} app={app} />
          </div>
        )}
      </div>
    </Box>
  );
};

export default HorizontalCard;
