import PageTitle from '../parts/PageTitle';
import { Stack, Typography } from '@mui/material';

export const Terms = () => {
  return (
    <div style={{ margin: "100px 50px 0 50px" }}>
      <PageTitle title={"Terms of Use"} />
      <Stack py={3} textAlign={"left"}>
        <Typography variant={"h5"}>
          <strong>Poro Terms of Use</strong>
        </Typography>
        <Typography variant={"body1"}>
          <em>These Terms of Use are effective as of February 27, 2023.</em>
        </Typography>
        <Typography variant={"h5"}>
          <strong>Thank you for joining Poro!</strong>
        </Typography>
        <Typography variant={"body1"}>
          The Poro Terms of Service (“Terms”) describe your rights and
          responsibilities as a customer and govern your use of the free and
          paid services, software and websites (collectively, the “Service”)
          provided by Motomtech LLC., dba Poro (“Poro”, “we”, “our”, etc.), and
          any data, text, files, information, usernames, images, graphics,
          photos, profiles, audio and video clips, sounds, musical works, works
          of authorship, applications, links, created tasks and associated
          information, text, files and other content or materials (together,
          “Content”) uploaded, downloaded, or appearing on our websites or
          applications.
        </Typography>
        <Typography variant={"body1"}>
          Our <u>Privacy Policy</u>explains how we collect and use your
          information. By using our Service, you agree to be bound by these
          Terms and our Privacy Policy. If you are using our Service on behalf
          of an organization or entity (“Organization”) or someone else is using
          our Service on your behalf (e.g., assistants, administrators,
          consultants), then you are agreeing to these Terms on behalf of that
          Organization and you represent and warrant that you have the authority
          to bind the Organization to these Terms. In that case, “you” and
          “your” refers to that Organization.
        </Typography>
        <Typography variant={"body1"}>
          Our Terms and Privacy Policy affect your legal rights and obligations.
          If you do not agree to be bound by all of these, please do not access
          or use our Service.
        </Typography>
        <Typography variant={"h5"}>
          <strong>Your User Account and Content</strong>
        </Typography>
        <ol>
          <li>
            You must provide accurate information when you create your Poro
            account and are responsible for safeguarding your password and all
            activities that occur under your account.&nbsp;
          </li>
          <li>
            Please notify us immediately if you become aware of any breach of
            security or unauthorized use of your account.
          </li>
          <li>
            {`You may not use another user’s account without permission. Your
                  account must be registered by a person of 13 years of age or older.
                  Accounts registered by "bots" or other automated methods are not
                  permitted.&nbsp;`}
          </li>
          <li>
            You may not disassemble, decompile, or reverse engineer the Service
            or attempt or assist anyone else to do so.
          </li>
          <li>
            You may not use the Service for any unauthorized or illegal purpose
            and must comply with all applicable laws, rules, and regulations to
            your use of the Service and your Content, including but not limited
            to, copyright laws.
          </li>
        </ol>
        <Typography variant={"h5"}>
          <strong>Fees &amp; Payment Terms&nbsp;</strong>
        </Typography>
        <Typography variant={"body1"}>
          The application is free of charge for all users.&nbsp;
        </Typography>
        <Typography variant={"h5"}>
          <strong>Arbitration Notice</strong>
        </Typography>
        <Typography variant={"body1"}>
          You agree that disputes between you and us will be resolved by
          binding, individual arbitration and you waive your right to
          participate in a class action lawsuit or class-wide arbitration.
        </Typography>
        <Typography variant={"h5"}>
          <strong>Your Use of Our Service</strong>
        </Typography>
        <Typography variant={"body1"}>
          As part of the Service, we may provide downloadable client software
          (the “Software”) for your use in connection with the Service. This
          Software may update automatically and if such Software is designed for
          use on a specific mobile or desktop operating system, then a
          compatible system is required for use. As long as you comply with
          these Terms, we grant you a limited, nonexclusive, nontransferable,
          revocable license to use the Software, solely to access the Service;
          provided, however, that this license does not constitute a sale of the
          Software or any copy thereof, and as between you and us, we retain all
          right, title and interest in the Software. You agree that you will not
          copy, reproduce, republish, frame, download, transmit, modify,
          display, reverse engineer, sell, or participate in any sale of, rent,
          lease, loan, assign, distribute, license, sublicense, or exploit in
          any way, in whole or in part, our Content, the Services or any related
          software, except as expressly stated in these Terms. You may not&nbsp;
        </Typography>
        <ul>
          <li>
            attempt to restrict another user from using or enjoying the Service,
          </li>
          <li>encourage or facilitate violations of any of our terms,&nbsp;</li>
          <li>
            change, modify, adapt, or alter the Service or change, modify, or
            alter a website to falsely imply that it is associated with the
            Service or with us,&nbsp;
          </li>
          <li>
            interfere or disrupt the Service or servers, or networks connected
            to the Service, including by transmitting any worms, viruses,
            spyware, malware, or any other code of a destructive or disruptive
            nature,&nbsp;
          </li>
          <li>
            {`inject content or code or otherwise alter or interfere with the way
                  any our page is rendered or displayed in a user's browser or
                  device,&nbsp;`}
          </li>
          <li>
            {`post, upload, share, submit or otherwise provide Inappropriate
                  content that is deceptive, fraudulent, illegal, obscene, defamatory,
                  libelous, threatening, harmful to minors, pornographic (including
                  child pornography, which we will remove and report to law
                  enforcement, including the National Center for Missing and Exploited
                  Children), indecent, harassing, hateful, encourages illegal or
                  tortious conduct or that is otherwise inappropriate, attacks others
                  based on their race, ethnicity, national origin, religion, sex,
                  gender, sexual orientation, disability, or medical condition or is
                  intended to be inflammatory,&nbsp;`}
          </li>
          <li>
            attempt to overwhelm or attempt to overwhelm our infrastructure by
            imposing an unreasonably large load on our systems that consume
            extraordinary resources (CPUs, memory, disk space, bandwidth,
            etc.),&nbsp;
          </li>
          <li>
            use the Service to or for any illegal purpose, or in violation of
            any laws (including without limitation data, privacy, and export
            control laws) or to stalk, harass, or post direct, specific threats
            of violence against others,&nbsp;
          </li>
          <li>
            access our private API by any means other than those expressly
            permitted by us,
          </li>
          <li>
            use the Service to Misrepresent yourself or disguising the origin of
            any content,&nbsp;
          </li>
          <li>
            {`violate the privacy of others, including publishing or posting other
                  people's private and confidential information without their express
                  permission, or collecting or gathering other people’s personal
                  information (including account names or information) from our
                  services, or generate or send unsolicited communications,
                  advertising or spam, or to solicit our users for commercial
                  purposes, disparage Poro or our partners, vendors, or affiliates, or
                  promote or advertise products or services other than your own
                  without our authorization.`}
          </li>
        </ul>
        <Typography variant={"body1"}>
          {`Violation of these Terms may, in our sole discretion, result in
                termination of your account. We fully reserve the right to investigate
                and prosecute violations of any of these Terms of the law. We may
                involve and cooperate with law enforcement authorities in prosecuting
                Users who violate the Terms. You acknowledge that we have no
                obligation to prescreen or monitor your access to or use of our
                Service or any information, materials or other content provided or
                made available through our Service but has the right to do so. You
                hereby agree that we may, in our sole discretion, remove or delete any
                data, accounts or other content that violates these Terms or that is
                otherwise objectionable.`}
        </Typography>
        <Typography variant={"body1"}>
          {`If you decide to use a third-party application in connection with your
                use of the Service, you are consenting to your Content being shared
                with such third-party application. To understand how such third-party
                application provider uses your Content and other information, you
                should review their privacy policy.`}
        </Typography>
        <Typography variant={"h5"}>
          <strong>Rights</strong>
        </Typography>
        <ol>
          <li>
            {`For purposes of these Terms: "User Content" means any Content that
                  Account holders (including you) make available through the Services.
                  Content includes without limitation User Content.`}
          </li>
        </ol>
        <Typography variant={"body1"}>
          <em>A. Content Ownership that We Make&nbsp;</em>
        </Typography>
        <Typography variant={"body1"}>
          {`Unless otherwise stated, all materials contained on or within the
                Services, including, but not limited to, text, graphics, images, code,
                illustrations, designs, icons, photographs, video clips, and written
                and other materials (collectively, "Poro Content"), as well as their
                selection and arrangement, are protected by copyright, trademark,
                trade dress, patent, and/or other intellectual property laws, and
                unauthorized use of Poro Content may violate such laws and these
                Terms. Except as expressly provided in these Terms, we do not grant
                any express or implied rights to use Poro Content. You agree that you
                will not copy, reproduce, republish, frame, download, transmit,
                modify, display, reverse engineer, sell, or participate in any sale
                of, rent, lease, loan, assign, distribute, license, sublicense, or
                exploit in any way, in whole or in part, Poro Content, the Services or
                any related software or Client Software as defined above, except as
                expressly stated in these Terms. You agree not to remove, alter, or
                obscure any copyright, trademark, service mark, or other proprietary
                rights notices incorporated in or accompanying the Services or
                Content. You acknowledge that the Services and Content are protected
                by copyright, trademark, and other laws of the United States and
                foreign countries.`}
        </Typography>
        <Typography variant={"body1"}>
          <em>B. Rights in User Content&nbsp;</em>
        </Typography>
        <Typography variant={"body1"}>
          {`We claim no ownership rights over the Content submitted or created
                exclusively by you in your Poro Service account. Any Content that is
                yours remains yours. These Terms of Service do not grant us any
                licenses or rights to your Content except for the limited rights
                needed for us to provide the Poro Service to you. Notwithstanding the
                foregoing, we may access Content to determine how we can improve our
                Service, and to determine customer satisfaction. Similarly, any
                reporting data we collect from your use of the Poro Service remains
                yours. By using the Poro Service you agree that we can use this data
                to provide the Poro Service to you, and you also agree that as long as
                the data is anonymized and does not identify you, we can combine this
                data with the anonymized data of other companies to provide
                benchmarking, public reports or to otherwise use it to provide the
                Poro Service.`}
        </Typography>
        <Typography variant={"body1"}>
          <em>C. Alerts and Notification&nbsp;</em>
        </Typography>
        <Typography variant={"body1"}>
          {`As part of the Services we provide, you may (if enabled) receive push
                notifications, text messages, alerts, emails, or other types of
                messages directly sent to you outside or inside the App
                ("Notifications"). You can opt in or out of these Notifications
                through the Services (with the possible exception of infrequent,
                important service announcements and administrative messages).`}
        </Typography>
        <ol start={3}>
          <li>
            {`Some of the Service may be supported by advertising revenue and may
                  display advertisements and promotions, and you hereby agree that we
                  may place such advertising and promotions on the Service or on,
                  about, or in conjunction with your Content. The manner, mode and
                  extent of such advertising and promotions are subject to change
                  without specific notice to you. You acknowledge that we may not
                  always identify paid services, sponsored content, or commercial
                  communications as such.`}
          </li>
        </ol>
        <ol start={4}>
          <li>
            {`You represent and warrant that: (i) you own the Content posted by
                  you on or through the Service or otherwise have the right to grant
                  the rights and licenses set forth in these Terms of Use; (ii) the
                  posting and use of your Content on or through the Service does not
                  violate, misappropriate, or infringe on the rights of any third
                  party, including, without limitation, privacy rights, publicity
                  rights, copyrights, trademark, and/or other intellectual property
                  rights; (iii) you agree to pay for all royalties, fees, and any
                  other monies owed by reason of Content you post on or through the
                  Service; and (iv) you have the legal right and capacity to enter
                  into these Terms of Use in your jurisdiction.`}
          </li>
        </ol>
        <ol start={5}>
          <li>
            {`The Poro names, logos and taglines are our trademarks, and may not
                  be copied, imitated or used, in whole or in part, without our prior
                  written permission. In addition, all page headers, custom graphics,
                  button icons, and scripts are our service marks, trademarks, and/or
                  trade dress, and may not be copied, imitated or used, in whole or in
                  part, without prior written permission from us.`}
          </li>
        </ol>
        <ol start={6}>
          <li>
            {`It is our intention for the Service to be available as much as
                  possible, but there may be occasions when the Service is
                  interrupted, including, without limitation, for scheduled
                  maintenance or upgrades, for emergency repairs, for unscheduled
                  downtime, for system and server failures, or due to failure of
                  telecommunications links and/or equipment. Consequently, we
                  encourage you to maintain your own backup of your Content. We do not
                  provide backup services and you agree that you will not rely on the
                  Service for the purposes of Content backup or storage. We will not
                  be liable to you for any modification, suspension, or
                  discontinuation of the Services, or the loss of any Content. You
                  also acknowledge that the Internet may be subject to breaches of
                  security and that the submission of Content or other information may
                  not be secure.`}
          </li>
        </ol>
        <ol start={7}>
          <li>
            {`You agree that we are not responsible for, and do not endorse,
                  Content posted within the Service. We do not have any obligation to
                  prescreen, monitor, edit, or remove any Content. If your Content
                  violates these Terms, you may bear legal responsibility for that
                  Content.`}
          </li>
        </ol>
        <ol start={8}>
          <li>
            {`Except as otherwise described in the Service's`}
            <a
              href="https://clickup.com/privacy"
              data-cke-saved-href="https://clickup.com/privacy"
            >
              [Privacy Policy]
            </a>
            , as between you and us, any Content will be non-confidential and
            non-proprietary and we will not be liable for any use or disclosure
            of Content. You acknowledge and agree that your relationship with us
            is not a confidential, fiduciary, or other type of special
            relationship, and that your decision to submit any Content does not
            place us in a position that is any different from the position held
            by members of the general public, including with regard to your
            Content. None of your Content will be subject to any obligation of
            confidence on our part, and we will not be liable for any use or
            disclosure of any Content you provide.
          </li>
        </ol>
        <ol start={9}>
          <li>
            Subject to your agreement and continuing compliance with these Terms
            and any of our other relevant policies, we grant you a
            non-exclusive, non-transferable, revocable limited license to use
            the Service solely for its intended purposes. You agree not to use
            the Service for any other purpose.&nbsp;
          </li>
        </ol>
        <Typography variant={"body1"}>
          This license is revocable at any time. This license is subject to
          these Terms and does not include:
        </Typography>
        <ul>
          <li>
            The distribution, public performance, or public display of Our
            Content;
          </li>
          <li>
            Modifying or otherwise making any derivative uses of the Services or
            Our Content, or any portion thereof;
          </li>
          <li>
            Use of any scraping, data mining, robots, or similar data gathering
            or extraction methods;
          </li>
          <li>
            Downloading (other than page caching) any portion of the Services,
            Our Content, or any information contained therein, except as
            expressly permitted on the Services;
          </li>
          <li>
            Accessing our API with an unauthorized or third-party client; and
          </li>
          <li>
            Any use of the Services or Our Content other than for their intended
            purposes.
          </li>
          <li>
            Any use of the Services or Our Content other than as specifically
            authorized in these Terms, without our prior written permission, is
            strictly prohibited and will terminate the license to use our
            Service and Site granted in these Terms.&nbsp;
          </li>
        </ul>
        <ol start={10}>
          <li>
            We welcome ideas and suggestions, but it is our policy not to accept
            or consider content, information, ideas, suggestions, or other
            materials except those we have specifically requested and to which
            certain specific terms, conditions and requirements may apply. If,
            despite our policy, you choose to send us content, information,
            ideas, suggestions, or other materials, you further agree to the
            following: For all reviews, comments, feedback, postcards,
            suggestions, ideas, and other submissions disclosed, submitted, or
            offered to us via our Service, our sites, by e-mail or telephone, by
            mail, or otherwise disclosed, submitted or offered in connection
            with your use of this Service (collectively, “User Submissions”) you
            grant us a royalty-free, irrevocable, transferable right and license
            to use the User Submissions however we desire, including without
            limitation, to copy, modify, delete in its entirety, adapt, publish,
            translate, create derivative works from, and/or sell and/or
            distribute such User Submissions and/or incorporate such User
            Submissions into any form, medium, or technology throughout the
            world.
          </li>
        </ol>
        <Typography variant={"body1"}>
          We will be entitled to use, reproduce, disclose, modify, adapt, create
          derivative works from, publish, display, and distribute any User
          Submissions you submit for any purpose whatsoever, without restriction
          and without compensating you in any way. We are and shall be under no
          obligation (1) to maintain any User Submissions in confidence; (2) to
          pay to user any compensation for any User Submissions; or (3) to
          respond to any User Submissions. You agree that any User Submissions
          submitted by you to us will not violate the right of any third party,
          including without limitation, copyright, trademark, privacy, or other
          personal or proprietary right(s), and will not cause injury to any
          person or entity. You further agree that no User Submissions submitted
          by you to us will be or contain libelous or otherwise unlawful,
          threatening, abusive or obscene material, or contain software viruses,
          political campaigning, commercial solicitation, chain letters, mass
          mailings or any form of “spam”.
        </Typography>
        <Typography variant={"body1"}>
          By using this Service, you agree to the above terms regarding User
          Submissions. Please note that User Submissions shall not under any
          circumstances be considered User Content as defined in paragraph 1,
          subsection B in this section.
        </Typography>
        <Typography variant={"h5"}>
          <strong>Trial Service; Pre-Released Services</strong>
        </Typography>
        <ol>
          <li>
            <em>Trial Service.</em> We may offer, from time to time, part or all
            of our Services on a free, no-obligation trial version (“Trial
            Service”). The term of the Trial Service shall be as communicated to
            you, within the Service unless terminated earlier by you or us, for
            any reason or for no reason. We reserve the right to modify, cancel
            and/or limit any Trial Service at any time and without
            liability.&nbsp;
          </li>
          <li>
            <em>Pre-Released Services.</em> Note that we may offer, from time to
            time, certain Services in an Alpha or Beta versions (the
            “Pre-Released Services”) and we use best endeavors to identify such
            Pre-Released Services as such. Pre-Released Services are Services
            that are still under development, and as such they may be inoperable
            or incomplete, and may contain bugs, suffer disruptions and/or not
            operate as intended and designated, more than usual.
          </li>
          <li>
            <em>Governing Terms of Trial Service and Pre-Released Services.</em>{" "}
            In respect of any Trial or Pre-Released Services: (i) services are
            licensed on an “As-Is”, “With All Faults”, “As Available” basis,
            with no warranties, express or implied, of any kind; (ii) in no
            event shall the total aggregate liability of Poro or its third-party
            service providers in connection with these terms exceed $100. We
            make no promises that any Trial Service and/or Pre-Released Services
            will be made available.
          </li>
        </ol>
        <Typography variant={"h5"}>
          <strong>Administrators&nbsp;</strong>
        </Typography>
        <Typography variant={"body1"}>
          You may choose to specify certain users as Administrators, who will
          have important rights and controls over your use of our Services and
          user accounts. This may include creating, de-provisioning, monitoring
          or modifying “End User” accounts, setting user permissions and
          managing access to your data. Administrators may also take over
          management of accounts previously registered using an email address
          belonging to your domain (which become “managed accounts”). You are
          responsible for whom you allow to become Administrators and any
          actions they take, including as described above. You agree that our
          responsibilities do not extend to the internal management or
          administration of your use of Poro.
        </Typography>
        <ol>
          <li>
            <em>Reseller as Administrator. </em>If you order Poro through a
            Reseller, then you are responsible for determining whether the
            Reseller may serve as an Administrator and for any related rights or
            obligations in your applicable agreement with the Reseller. As
            between you and Atlassian, you are solely responsible for any access
            by Reseller to your accounts or your other End User Accounts.
          </li>
          <li>
            <em>End User Consent.</em>&nbsp;You will provide all required
            disclosures to and will obtain and maintain all required consents
            from End Users to allow administrators to have the access described
            in these Terms and the{" "}
            <a
              href="https://www.atlassian.com/legal/privacy-policy"
              data-cke-saved-href="https://www.atlassian.com/legal/privacy-policy"
            >
              Privacy Policy
            </a>
            . You will provide evidence of such consents upon our reasonable
            request.&nbsp;
          </li>
          <li>
            <em>Responsibility for End Users.</em> Poro has various user
            onboarding flows. Some require users to be designated by
            Administrators; some allow users to sign up for individual accounts
            which can become associated with teams or organizations later; and
            some may allow users to invite other users. You are responsible for
            understanding the settings and controls you use and for controlling
            whom you allow to become an End User. You are responsible for
            compliance with these Terms by all End Users, including for any
            payment obligations. Please note that you are responsible for the
            activities of all your End Users, including orders they may place
            and how End Users use Your Data, even if those End Users are not
            from your organization or domain. We may display our{" "}
            <a
              href="https://www.atlassian.com/legal/user-notice"
              data-cke-saved-href="https://www.atlassian.com/legal/user-notice"
            >
              User Notice
            </a>
            &nbsp;to End Users at sign up, account creation, product
            registration, or in-product.&nbsp;
          </li>
          <li>
            <em>Credentials.</em>&nbsp;You must require that all End Users keep
            their user IDs and passwords strictly confidential and do not share
            such information with any unauthorized person. User IDs are granted
            to individual, named persons and may not be shared. You are
            responsible for all actions taken using End User Accounts and
            passwords, and you agree to immediately notify us of any
            unauthorized use of which you become aware.
          </li>
        </ol>
        <Typography variant={"h5"}>
          <strong>Resellers&nbsp;</strong>
        </Typography>
        <Typography variant={"body1"}>
          If you purchased through an authorized partner or reseller of Poro
          (“Reseller”):
        </Typography>
        <Typography variant={"body1"}>
          Instead of paying us directly, you will pay the applicable amounts to
          the Reseller, as Poro between you and the Reseller. We may suspend or
          terminate your rights to use Poro if we do not receive the
          corresponding payment from the Reseller.
        </Typography>
        <ol>
          <li>
            Your “Order” details will be as stated in the Order placed with us
            by the Reseller on your behalf, and Reseller is responsible for the
            accuracy of any such Order as communicated to us.
          </li>
          <li>
            If you are entitled to a refund under these Terms, then unless we
            specify otherwise, we will refund any applicable fees to the
            Reseller and the Reseller will be solely responsible for refunding
            the appropriate amounts to you.
          </li>
          <li>
            Resellers are not authorized to modify these Terms or make any
            promises or commitments on our behalf, and we are not bound by any
            obligations to you other than as set forth in these Terms.
          </li>
          <li>
            The amount paid or payable by the Reseller to us for your use under
            these Terms will be deemed the amount actually paid or payable by
            you to us under these Terms.
          </li>
        </ol>
        <Typography variant={"h5"}>
          <strong>Reporting Copyright and Other IP Violations</strong>
        </Typography>
        <Typography variant={"body1"}>
          {`Our policy is to respond to notices of alleged copyright infringement
                that comply with the Digital Millennium Copyright Act ("DMCA"). If you
                believe your work has been copied in a way that constitutes copyright
                infringement, please provide a notice containing the following
                information to our Copyright Agent:`}
        </Typography>
        <ul>
          <li>
            An electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright interest,
          </li>
          <li>
            A description of the copyrighted work that you claim has been
            infringed,
          </li>
          <li>
            A description of where the material that you claim is infringing is
            located on our site,
          </li>
          <li>Your address, telephone number, and e-mail address,</li>
          <li>
            A statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law, and
          </li>
          <li>
            {`A statement by you, made under penalty of perjury, that the above
                  information in your notice is accurate and that you are the
                  copyright owner or authorized to act on the copyright owner's
                  behalf.`}
          </li>
        </ul>
        <Typography variant={"body1"}>
          We reserve the right to remove Content alleged to be infringing
          without prior notice and at our sole discretion. In appropriate
          circumstances, we will also terminate a user’s account if the user is
          determined to be a repeat infringer.
        </Typography>
        <Typography variant={"body1"}>
          Our Copyright Agent for Notice of claims of copyright infringement on
          the Site is Dan Jones, who can be reached at contact
          <a
            href="mailto:dan@agreed.com"
            data-cke-saved-href="mailto:dan@agreed.com"
          >
            <u>@Poro.</u>
          </a>
          al
        </Typography>
        <Typography variant={"h5"}>
          <strong>Disclaimer of Warranties</strong>
        </Typography>
        <Typography variant={"body1"}>
          {`THE SERVICE, INCLUDING, WITHOUT LIMITATION, OUR CONTENT, IS PROVIDED
                ON AN "AS IS", "AS AVAILABLE", AND "WITH ALL FAULTS" BASIS. TO THE
                FULLEST EXTENT PERMISSIBLE BY LAW, NEITHER WE NOR ANY OF OUR
                EMPLOYEES, MANAGERS, OFFICERS, CONTRACTORS, SUPPLIERS, LICENSORS, OR
                AGENTS (COLLECTIVELY, THE "Poro PARTIES") MAKE ANY REPRESENTATIONS OR
                WARRANTIES OR ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED,
                AS TO: (A) THE SERVICE; (B) OUR CONTENT; (C) USER CONTENT; OR (D)
                SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO US OR VIA
                THE SERVICE. IN ADDITION, THE Poro PARTIES HEREBY DISCLAIM ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM
                INTEGRATION, AND FREEDOM FROM COMPUTER VIRUS.`}
        </Typography>
        <Typography variant={"body1"}>
          {`THE Poro PARTIES DO NOT REPRESENT OR WARRANT THAT THE SERVICE WILL BE
                ERROR-FREE OR UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; OR THAT
                THE SERVICE OR THE SERVER THAT MAKES THE SERVICE AVAILABLE IS FREE
                FROM ANY HARMFUL COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES
                AND SECURITY BREACHES OF ANY KIND. THE Poro PARTIES DO NOT MAKE ANY
                REPRESENTATIONS OR WARRANTIES THAT THE INFORMATION (INCLUDING ANY
                INSTRUCTIONS) ON THE SERVICE IS ACCURATE, COMPLETE, OR USEFUL. YOU
                ACKNOWLEDGE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE
                Poro PARTIES DO NOT WARRANT THAT YOUR USE OF THE SERVICE IS LAWFUL IN
                ANY PARTICULAR JURISDICTION, AND THE Poro PARTIES SPECIFICALLY
                DISCLAIM SUCH WARRANTIES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE
                DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY
                NOT APPLY TO YOU TO THE EXTENT SUCH JURISDICTION'S LAW IS APPLICABLE
                TO YOU AND THESE TERMS OF USE.`}
        </Typography>
        <Typography variant={"body1"}>
          BY ACCESSING OR USING THE SERVICE YOU REPRESENT AND WARRANT THAT YOUR
          ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE
          THE SERVICE.
        </Typography>
        <Typography variant={"body1"}>
          THE Poro PARTIES DO NOT ENDORSE CONTENT AND SPECIFICALLY DISCLAIM ANY
          RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY LOSS,
          DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY,
          CLAIM, LIABILITY, OR OTHER CAUSE OF ANY KIND OR CHARACTER BASED UPON
          OR RESULTING FROM ANY CONTENT.&nbsp;
        </Typography>
        <Typography variant={"h5"}>
          <strong>License Limitations</strong>
        </Typography>
        <Typography variant={"body1"}>
          ANY ATTEMPT BY YOU TO DISRUPT OR INTERFERE WITH THE SERVICE INCLUDING
          UNDERMINING OR MANIPULATING THE LEGITIMATE OPERATION OF ANY OF OUR
          SITES OR SERVICE IS A VIOLATION OF OUR POLICY AND MAY BE A VIOLATION
          OF CRIMINAL AND CIVIL LAWS.
        </Typography>
        <Typography variant={"body1"}>
          WITHOUT LIMITING ANY OTHER REMEDIES, WE MAY LIMIT, SUSPEND, TERMINATE,
          MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE SERVICE OR PORTIONS
          THEREOF IF YOU ARE, OR WE SUSPECT THAT YOU ARE, FAILING TO COMPLY WITH
          ANY TERMS OF SERVICE OR FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR
          IMPROPER USE OF THE SERVICE, WITH OR WITHOUT NOTICE TO YOU. YOU CAN
          LOSE YOUR ACCOUNT AND ANY USER CONTENT AS A RESULT OF ACCOUNT
          TERMINATION OR LIMITATION, AS WELL AS ANY BENEFITS, PRIVILEGES, EARNED
          ITEMS, AND PURCHASED ITEMS ASSOCIATED WITH YOUR USE OF THE SERVICE,
          AND WE ARE UNDER NO OBLIGATION TO COMPENSATE YOU FOR ANY SUCH LOSSES
          OR RESULTS.
        </Typography>
        <Typography variant={"body1"}>
          WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND, OR
          TERMINATE THE SERVICE AND USER ACCOUNTS OR PORTIONS THEREOF, PROHIBIT
          ACCESS TO OUR SERVICES AND SITES, AND THEIR CONTENT, AND TOOLS, DELAY
          OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND LEGAL STEPS TO
          PREVENT USERS FROM ACCESSING THE SERVICE IF WE BELIEVE THAT THEY ARE
          CREATING RISK OR POSSIBLE LEGAL LIABILITIES, INFRINGING THE
          INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING
          INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR TERMS OR POLICIES.
          ADDITIONALLY, WE MAY, IN APPROPRIATE CIRCUMSTANCES AND AT OUR SOLE
          DISCRETION, SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO MAY BE REPEAT
          INFRINGERS OF THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS.
        </Typography>
        <Typography variant={"body1"}>
          We reserve the right to stop offering and/or supporting the Service or
          a particular part of the Service at any time either permanently or
          temporarily. In such event, we shall not be required to provide
          refunds, benefits or other compensation to users in connection with
          such discontinued elements of the Service.
        </Typography>
        <Typography variant={"h5"}>
          <strong>Limitation of Liability; Waiver</strong>
        </Typography>
        <Typography variant={"body1"}>
          {`UNDER NO CIRCUMSTANCES WILL THE Poro PARTIES BE LIABLE TO YOU FOR ANY
                LOSS OR DAMAGES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, FOR ANY
                DIRECT, INDIRECT, ECONOMIC, EXEMPLARY, SPECIAL, PUNITIVE, INCIDENTAL,
                OR CONSEQUENTIAL LOSSES OR DAMAGES) THAT ARE DIRECTLY OR INDIRECTLY
                RELATED TO: (A) THE SERVICE; (B) OUR CONTENT; (C) USER CONTENT; (D)
                YOUR USE OF, INABILITY TO USE, OR THE PERFORMANCE OF THE SERVICE; (E)
                ANY ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY THE Poro
                PARTIES OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR OR ANY OTHER
                PARTY'S USE OF THE SERVICE; (F) ANY ACTION TAKEN IN CONNECTION WITH
                COPYRIGHT OR OTHER INTELLECTUAL PROPERTY OWNERS; (G) ANY ERRORS OR
                OMISSIONS IN THE SERVICE'S OPERATION; OR (H) ANY DAMAGE TO ANY USER'S
                COMPUTER, MOBILE DEVICE, OR OTHER EQUIPMENT OR TECHNOLOGY INCLUDING,
                WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY VIRUS,
                BUGS, TAMPERING, FRAUD, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY
                IN OPERATION OR TRANSMISSION, COMPUTER LINE OR NETWORK FAILURE OR ANY
                OTHER TECHNICAL OR OTHER MALFUNCTION, INCLUDING, WITHOUT LIMITATION,
                DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK
                STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION,
                EVEN IF FORESEEABLE OR EVEN IF THE Poro PARTIES HAVE BEEN ADVISED OF
                OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER IN AN
                ACTION OF CONTRACT, NEGLIGENCE, STRICT LIABILITY OR TORT (INCLUDING,
                WITHOUT LIMITATION, WHETHER CAUSED IN WHOLE OR IN PART BY NEGLIGENCE,
                ACTS OF GOD, TELECOMMUNICATIONS FAILURE, OR THEFT OR DESTRUCTION OF
                THE SERVICE). IN NO EVENT WILL THE Poro PARTIES BE LIABLE TO YOU OR
                ANYONE ELSE FOR LOSS, DAMAGE OR INJURY, INCLUDING, WITHOUT LIMITATION,
                DEATH OR PERSONAL INJURY. SOME STATES DO NOT ALLOW THE EXCLUSION OR
                LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT WILL THE
                Poro PARTIES TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES
                OR ACTION EXCEED ONE HUNDRED UNITED STATES DOLLARS ($100.00).`}
        </Typography>
        <Typography variant={"body1"}>
          YOU AGREE THAT IN THE EVENT YOU INCUR ANY DAMAGES, LOSSES, OR INJURIES
          THAT ARISE OUT OF OUR ACTS OR OMISSIONS, THE DAMAGES, IF ANY, CAUSED
          TO YOU ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN
          INJUNCTION PREVENTING ANY EXPLOITATION OF ANY WEB SITE, SERVICE,
          PROPERTY, PRODUCT, OR OTHER CONTENT OWNED OR CONTROLLED BY THE Poro
          PARTIES, AND YOU WILL HAVE NO RIGHTS TO ENJOIN OR RESTRAIN THE
          DEVELOPMENT, PRODUCTION, DISTRIBUTION, ADVERTISING, EXHIBITION, OR
          EXPLOITATION OF ANY WEB SITE, PROPERTY, PRODUCT, SERVICE, OR OTHER
          CONTENT OWNED OR CONTROLLED BY THE Poro PARTIES.
        </Typography>
        <Typography variant={"body1"}>
          {`BY ACCESSING THE SERVICE, YOU UNDERSTAND THAT YOU MAY BE WAIVING
                RIGHTS WITH RESPECT TO CLAIMS THAT ARE AT THIS TIME UNKNOWN OR
                UNSUSPECTED, AND IN ACCORDANCE WITH SUCH WAIVER, YOU ACKNOWLEDGE THAT
                YOU HAVE READ AND UNDERSTAND, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS
                OF SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY SIMILAR LAW
                OF ANY STATE OR TERRITORY, WHICH PROVIDES AS FOLLOWS: "A GENERAL
                RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
                SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE,
                WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT
                WITH THE DEBTOR."`}
        </Typography>
        <Typography variant={"body1"}>
          WE ARE NOT RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA
          OF THIRD PARTIES, AND YOU RELEASE THE Poro PARTIES FROM ANY CLAIMS AND
          DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED
          WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES.
        </Typography>
        <Typography variant={"h5"}>
          <strong>Indemnification</strong>
        </Typography>
        <Typography variant={"body1"}>
          {`You(and also any third party for whom you operate an account or
                activity on the Service) agree to defend (at our request), indemnify,
                and hold the Poro Parties harmless from and against any claims,
                liabilities, damages, losses, and expenses, including without
                limitation, reasonable attorney's fees and costs, arising out of or in
                any way connected with any of the following (including as a result of
                your direct activities on the Service or those conducted on your
                behalf):&nbsp;`}
        </Typography>
        <Typography variant={"body1"}>
          (i) your Content or your access to or use of the Service;&nbsp;
        </Typography>
        <Typography variant={"body1"}>
          (ii) your breach or alleged breach of these Terms;&nbsp;
        </Typography>
        <Typography variant={"body1"}>
          (iii) your violation of any third-party right, including without
          limitation, any intellectual property right, publicity,
          confidentiality, property, or privacy right;
        </Typography>
        <Typography variant={"body1"}>
          (iv) your violation of any laws, rules, regulations, codes, statutes,
          ordinances, or orders of any governmental and quasi-governmental
          authorities, including, without limitation, all regulatory,
          administrative and legislative authorities; or&nbsp;
        </Typography>
        <Typography variant={"body1"}>
          (v) any misrepresentation made by you.&nbsp;
        </Typography>
        <Typography variant={"body1"}>
          You will cooperate as fully required by us in the defense of any
          claim. We reserve the right to assume the exclusive defense and
          control of any matter subject to indemnification by you, and you will
          not in any event settle any claim without our prior written consent.
          Notwithstanding the foregoing, you agree that the cost of any such
          defense will be at your sole and exclusive expense.&nbsp;
        </Typography>
        <Typography variant={"h5"}>
          <strong>Arbitration</strong>
        </Typography>
        <Typography variant={"body1"}>
          {`Except if you opt-out or for disputes relating to your or our
                intellectual property (such as trademarks, trade dress, domain names,
                trade secrets, copyrights, and patents ("Excluded Disputes"), you
                agree that all disputes between you and us (whether or not such
                dispute involves a third party) with regard to your relationship with
                us, including without limitation disputes related to these Terms, your
                use of the Service, and/or rights of privacy and/or publicity, will be
                resolved by binding, individual arbitration under the American
                Arbitration Association's rules for arbitration of consumer-related
                disputes and you and we hereby expressly waive trial by jury. You may
                bring claims only on your own behalf. Neither you nor we will
                participate in a class action or class-wide arbitration for any claims
                covered by this agreement. You also agree not to participate in claims
                brought by a private attorney, in a general or representative
                capacity, or consolidated claims involving another person's account,
                if we are a party to the proceeding. This dispute resolution provision
                will be governed by the Federal Arbitration Act. In the event the
                American Arbitration Association is unwilling or unable to set a
                hearing date within one hundred and sixty (160) days of filing the
                case, then either we or you can elect to have the arbitration
                administered instead by the Judicial Arbitration and Mediation
                Services. Judgment on the award rendered by the arbitrator may be
                entered in any court having competent jurisdiction. Any provision of
                applicable law notwithstanding, the arbitrator will not have authority
                to award damages, remedies or awards that conflict with these Terms.`}
        </Typography>
        <Typography variant={"body1"}>
          You may opt out of this agreement to arbitrate, as specified above. If
          you do so, neither you nor we can require the other to participate in
          an arbitration proceeding. To opt out, you must notify us in writing
          within 30 days of the date that you first became subject to this
          arbitration provision. You must use this address to opt out:
        </Typography>
        <Typography variant={"body1"}>
          <br />
          ATTN: Privacy Agent Motomtech LLC
          <br />
          26 S Rio Grande St, #2072, Salt Lake City, UT 84101, United States
        </Typography>
        <Typography variant={"body1"}>
          You must include your name and residence address, the phone number and
          username that you use for your account, and a clear statement that you
          want to opt out of this arbitration agreement.
        </Typography>
        <Typography variant={"body1"}>
          If the prohibition against class actions and other claims brought on
          behalf of third parties contained above is found to be unenforceable,
          then all of the preceding language in this Arbitration section will be
          null and void. This arbitration agreement will survive the termination
          of your relationship with us.&nbsp;
        </Typography>
        <Typography variant={"h5"}>
          <strong>Time Limitation on Claims</strong>
        </Typography>
        <Typography variant={"body1"}>
          You agree that any claim you may have arising out of or related to
          your relationship with us must be filed within one year after such
          claim arose; otherwise, your claim is permanently barred.&nbsp;
        </Typography>
        <Typography variant={"h5"}>
          <strong>Governing Law &amp; Venue</strong>
        </Typography>
        <Typography variant={"body1"}>
          These Terms are governed by and construed in accordance with the laws
          of the State of Utah, without giving effect to any principles of
          conflicts of law AND WILL SPECIFICALLY NOT BE GOVERNED BY THE UNITED
          NATIONS CONVENTIONS ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS,
          IF OTHERWISE APPLICABLE. For any action at law or in equity relating
          to the arbitration provision of these Terms, the Excluded Disputes or
          if you opt out of the agreement to arbitrate, you agree to resolve any
          dispute you have with us exclusively in a state or federal court
          located in Salt Lake City, UT and to submit to the personal
          jurisdiction of the courts located in Salt Lake County for the purpose
          of litigating all such dispute
        </Typography>
        <Typography variant={"h5"}>
          <strong>Termination &amp; Severability</strong>
        </Typography>
        <Typography variant={"body1"}>
          We may terminate this Agreement at any time, with or without notice,
          for any reason.
        </Typography>
        <Typography variant={"body1"}>
          If any provision of these Terms is held to be unlawful, void, or for
          any reason unenforceable during arbitration or by a court of competent
          jurisdiction, then that provision will be deemed severable from these
          Terms and will not affect the validity and enforceability of any
          remaining provisions. Our failure to insist upon or enforce strict
          performance of any provision of these Terms will not be construed as a
          waiver of any provision or right. No waiver of any of these Terms will
          be deemed a further or continuing waiver of such term or condition or
          any other term or condition.
        </Typography>
        <Typography variant={"h5"}>
          <strong>Entire Agreement</strong>
        </Typography>
        <Typography variant={"body1"}>
          If you are using the Service on behalf of a legal entity, you
          represent that you are authorized to enter into an agreement on behalf
          of that legal entity. These Terms constitute the entire agreement
          between you and us and governs your use of the Service, superseding
          any prior agreements between you and us.&nbsp;You will not assign the
          Terms or assign any rights or delegate any obligations hereunder, in
          whole or in part, whether voluntarily or by operation of law, without
          our prior written consent. Any purported assignment or delegation by
          you without our appropriate prior written consent will be null and
          void. We may assign these Terms or any rights hereunder without your
          consent and the Terms will inure to the benefit of and be enforceable
          by our successor. Neither the course of conduct between the parties
          nor trade practice will act to modify the Terms. These Terms do not
          confer any third-party beneficiary rights.&nbsp;
        </Typography>
        <Typography variant={"h5"}>
          <strong>Territorial Restrictions</strong>
        </Typography>
        <Typography variant={"body1"}>
          The information provided within the Service is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country. We reserve the right to limit the
          availability of the Service or any portion of the Service, to any
          person, geographic area, or jurisdiction, at any time and in our sole
          discretion, and to limit the quantities of any content, program,
          product, service, or other feature that we provide.
        </Typography>
        <Typography variant={"body1"}>
          {`Software related to or made available by the Service may be subject to
                United States export controls. Thus, no software from the Service may
                be downloaded, exported, or re-exported: (a) into (or to a national or
                resident of) any country to which the United States has embargoed
                goods; or (b) to anyone on the U.S. Treasury Department's list of
                Specially Designated Nationals or the U.S. Commerce Department's Table
                of Deny Orders. By downloading any software related to the Service,
                you represent and warrant that you are not located in, under the
                control of, or a national or resident of, any such country or on any
                such list.`}
        </Typography>
        <Typography variant={"body1"}>
          These Terms of Use were written in English (US). To the extent any
          translated version of these Terms of Use conflicts with the English
          version, the English version controls.&nbsp;
        </Typography>
        <Typography variant={"h5"}>
          <strong>Government Use</strong>
        </Typography>
        <Typography variant={"body1"}>
          If you or your Organization is part of a U.S. Government agency,
          department or otherwise, either federal, state or local (a “Government
          Customer”), then Government Customer hereby agrees that the Service
          under these Terms qualifies as “Commercial Computer Software” and
          “Commercial Computer Software Documentation”, within the meaning of
          Federal Acquisition Regulation (“FAR”) 2.101, FAR 12.212, Defense
          Federal Acquisition Regulation Supplement (“DFARS”) 227.7201, and
          DFARS 252.227-7014. Government Customer further agrees that the terms
          of this Section 20 shall apply to Customer. Government Customer’s
          technical data and software rights related to the Service include only
          those rights customarily provided to the public as specified in these
          Terms in accordance with FAR 12.212, FAR 27.405-3, FAR 52.227-19,
          DFARS 227.7202-1 and General Services Acquisition Regulation (“GSAR”)
          552.212-4(w) (as applicable). In no event shall source code be
          provided or considered to be a deliverable or a software deliverable
          under these Terms. We grant no license whatsoever to any Government
          Customer to any source code contained in any deliverable or a software
          deliverable. If a Government Customer has a need for rights not
          granted under the Terms, it must negotiate with us to determine if
          there are acceptable terms for granting those rights, and a mutually
          acceptable written addendum specifically granting those rights must be
          included in any applicable agreement. Any unpublished-rights are
          reserved under applicable copyright laws. Any provisions contained in
          these Terms that contradict any Law applicable to a Government
          Customer, shall be limited solely to the extent permitted under such
          applicable Law.
        </Typography>
        <Typography variant={"h5"}>
          <strong>Export Controls; Sanctions.</strong>
        </Typography>
        <Typography variant={"body1"}>
          The Service may be subject to Israeli, U.S. or foreign export
          controls, laws and regulations (the “Export Controls”). You
          acknowledge and confirm that: (i) you are not located or use, export,
          re-export or import the Service (or any portion thereof) in or to, any
          person, entity, organization, jurisdiction or otherwise, in violation
          of the Export Controls; (ii) you are not (a) organized under the laws
          of, operating from, or otherwise ordinarily resident in a country or
          territory that is the target of comprehensive U.S. economic or trade
          sanctions, (b) identified on a list of prohibited or restricted
          persons, or (c) otherwise the target of U.S. sanctions. You are solely
          responsible for complying with applicable Export Controls and
          sanctions which may impose additional restrictions, prohibitions or
          requirements on the use, export, re-export or import of the Services
          and/or the Customer Data.&nbsp;
        </Typography>
      </Stack>
    </div>
  );
};
