import React from "react";

const RoundedButton = ({ style, text, link }) => {
  const buttonStyle = {
    display: "flex",
    width: "fit-content",
    padding: "20px 32px",
    fontSize: "18px",
    fontWeight: "600",
    backgroundColor: "#FC7C00",
    color: "#fff",
    border: "none",
    borderRadius: "28px",
    cursor: "pointer",
    textDecoration: "none",

    ...style,
  };
  return (
    <a href={link} className="roundedBtn" style={buttonStyle}>
      {text}
    </a>
  );
};

export default RoundedButton;
