import React from "react";
import RoundedButton from "./RoundedButton";
import SquareButton from "./SquareButton";
import Restorant from "../assets/images/homePage/Restaurants&Markets.png";
import PoroApp from "../assets/images/homePage/Poroservice.png";
import DeliveryApp from "../assets//images/homePage/Delivery.png";
const Cards = ({ item }) => {
  const title = item?.title;
  const text = item?.text;
  const btn = item?.btn;
  const app = item?.app;

  const ImgUrl = () => {
    if (app === "restorant") {
      return Restorant;
    } else if (app === "poroApp") {
      return PoroApp;
    } else return DeliveryApp;
  };
  const imgSrc = ImgUrl();
  return (
    <div className="card">
      <img className="card-img" alt="" src={imgSrc}></img>
      <div className="card-box">
        <p className="card-box-title"> {title}</p>
        <p className="card-box-text">{text}</p>
        {typeof btn === "string" ? (
          <RoundedButton
            link={"https://web.poro.al/"}
            text={btn}
            style={{ padding: "14px 20px" }}
          />
        ) : (
          <div className="card-box-buttons">
            <SquareButton device={btn?.deviceApple} app={app} />
            <SquareButton device={btn?.deviceAndroid} app={app} />
          </div>
        )}

        <div />
      </div>
    </div>
  );
};

export default Cards;
