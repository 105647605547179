import { Box } from '@mui/material';
import {useEffect} from "react";

export const QR = () => {
  const isAppleDevice =
    navigator.platform.indexOf('Mac') > -1 ||
    navigator.platform.indexOf('iPhone') > -1 ||
    navigator.platform.indexOf('iPad') > -1 ||
    navigator.platform.indexOf('iPod') > -1;

  useEffect(() => {
    window.location = isAppleDevice
      ? 'https://apps.apple.com/us/app/poro/id1673757616'
      : 'https://play.google.com/store/apps/details?id=com.poro.deliveryApp';
  }, [isAppleDevice]);
  
  return (
    <Box>
      <p>{isAppleDevice}</p>
    </Box>
  );
};
