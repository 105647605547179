import { HomeComponent } from "../components/HomeComponet";
import HomeServices from "../components/HomeServices";
import HomePoroSites from "../components/HomePoroSites";

export const Home = () => {
  return (
    <div>
      <HomeComponent />
      <HomeServices />
      <HomePoroSites />
    </div>
  );
};
