import React from "react";
import HorizontalCard from "../parts/HorizontalCard";
// import PoroTeam from "../assets/images/homePage/PoroTeam.png";
import DeliverySquare from "../assets/images/homePage/DeliverySquare.png";
import RoundedButton from "../parts/RoundedButton";
import SquareButton from "../parts/SquareButton";
import { Box } from "@mui/material";
import PoroTeam from "../assets/images/homePage/PoroTeam.png";
const HomePoroSites = () => {
  const data = [
    {
      img: DeliverySquare,
      title: "Poro Motorristi",
      description:
        "Poro Motoristi është ndihmësi juaj digjital për çdo dorëzim. Ai është i thjeshtë, i lehtë për t'u përdorur dhe ofron udhëzime të qarta për destinacionin tuaj. Poro Motorristi ju ndihmon të përqendroheni në atë që është më e rëndësishme - dorëzimin e ushqimit.",
      btn: true,
      id: "deliveryApp-section",
      app: "deliveryApp",
    },
    {
      img: PoroTeam,
      title: "Skuadra Poro ",
      description:
        "Kur i bashkoni platformës Poro, ju përfitoni nga mbështetja dhe ekspertiza e një skuadre me përvojë ndërkombëtare, e cila ka kontribuar në zhvillimin e shumë projekteve të suksesshme në fushën e bizneseve digjitale.",
      btn: false,
      app: "poroApp",
    },
  ];
  return (
    <div className="poroSites">
      <div className="poroSites-vertCard">
        <div
          className="poroSites-vertCard-content restaurant"
          id="restorants-section"
        >
          <div className="box">
            <h1 className="poroSites-vertCard-content-title">
              RESTORANTET DHE MARKETET
            </h1>
            <p className="poroSites-vertCard-content-text">
              Zgjeroni mundësitë e biznesit tuaj me Poro! Regjistrohuni falas,
              pavarësisht vendodhjes tuaj dhe filloni të përfitoni nga rritja e
              klientëve dhe shitjeve. Me Poro, biznesi juaj mund të arrijë
              potencialin e tij të plotë.
            </p>
            <RoundedButton
              link={"https://web.poro.al/"}
              text={"Regjistroni biznesin tuaj"}
            />
          </div>
        </div>

        <div
          className="poroSites-vertCard-content poroApp"
          id="mobileApp-section"
        >
          <div className="box">
            <h1 className="poroSites-vertCard-content-title">Poro App</h1>
            <p className="poroSites-vertCard-content-text">
              Poro App sjell botën e ushqimit dhe blerjeve të përditshme në
              dorën tuaj. Zgjidhni nga restorantet dhe marketet lokale,
              porosisni dhe merrni dërgesën tuaj kudo që jeni, ose thjeshtë
              tërhiqini në dyqan. Një botë e re e mundësive, një klikim larg.
            </p>
            <div className="poroSites-vertCard-content-btns">
              <SquareButton device={"apple"} app={"poroApp"} />
              <SquareButton device={"android"} app={"poroApp"} />
            </div>
          </div>
        </div>
      </div>
      <Box
        className="poroSites-horCard"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          gap: "172px",
          "@media (max-width: 768px)": {
            gap: "122px",
          },
        }}
      >
        {data.map((item, i) => {
          const isEven = i % 2 === 0;
          return (
            <HorizontalCard
              title={item?.title}
              description={item?.description}
              image={item?.img}
              reverse={isEven ? true : false}
              btn={item?.btn}
              key={i}
              id={item?.id}
              app={item.app}
            />
          );
        })}
      </Box>
    </div>
  );
};

export default HomePoroSites;
