import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const PageTitle = (props) => {
  const { title } = props;
  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      bgcolor={'#ececec'}
      py={5}
      borderRadius={3}
    >
      <Typography variant={'h2'} fontSize={24}>
        {title}
      </Typography>
    </Stack>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
};

export default PageTitle;
