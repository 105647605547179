import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/App.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home, Privacy, QR, Terms } from "./pages";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#FC7C00",
    },
    secondary: {
      main: "#113D3C",
    },
    light: {
      main: "#fff",
    },
  },
  typography: {
    body1: {
      marginBottom: 10,
    },
    h5: {
      marginBottom: 10,
    },
  },
});
theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/qr" element={<QR />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
